<template>
  <div class="metadata-list" v-if="hasMetadata">

    <h4>{{ $t("transfers.additional_notes") }}</h4>
    <div class="m-t" v-for="list in metadataList">
      <div v-show="list.records.length > 0">
        <h4>{{ $t(list.label) }}</h4>
        <ul class="list-unstyled">
          <li v-for="metadata in list.records">
            <b-row>
              <b-col>
                <div class="white-space-normal">{{ metadata.name }}</div>
              </b-col>
              <b-col>
                {{ metadata.value }}
              </b-col>
            </b-row>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "MetadataList",
  props: {
    value: {
      type: Object,
      default: {},
    }
  },
  computed: {
    hasMetadata() {
      return this.metadataList.reduce((r, o) => r + o.records.length, 0) > 0
    },
    metadataList() {
      return [
        {
          label: 'multiples.transfer.one',
          records: this.value.display_metadata || this.value.metadata || []
        },
        {
          label: 'common.start_city',
          records: this.value.start_shop.display_metadata || this.value.start_shop.metadata || []
        },
        {
          label: 'common.end_city',
          records: this.value.end_shop.display_metadata || this.value.end_shop.metadata || []
        },
      ]
    }
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
