<template>
  <div class="prepredict">

    <div class="wrapper wrapper-content animated fadeInDown">
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content no-borders b-r-lg">

              <!-- title -->
              <div class="row">
                <div class="col-md-4">
                  <div class="article">
                    <h1>{{ $t("multiples.transfer.other") }}</h1>
                  </div>
                </div>
              </div>

              <!-- table -->
              <div class="row">
                <div class="col-lg-12">
                  <div class="panel blank-panel">

                    <b-tabs content-class="mt-3" fill>
                      <b-tab :lazy="tab.recordsTotal < 0" v-for="tab in tabs" :title="$tabLabel(tab)">
                        <datatable :columns="tab.columns" :fetch-records="tab.fetchFunction" :actions="tab.actions"/>
                      </b-tab>
                    </b-tabs>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <default-modal v-model="clusterModalShow" :id="clusterModalId" :title="clusterModalTitle"
                   :type="clusterModalType" :inputs="clusterModalInputs" :fetch-record="fetchCluster"
                   :disabled="clusterModalType==='show'"
                   :submitRecord="submitCluster"/>

    <transition-modal v-model="transitionModalShow" :id="transitionModalId" :title="transitionModalTitle"
                      :type="transitionModalType"/>
    <transition-detail-modal v-model="transitionDetailModalShow" :id="transitionModalId"/>

  </div>
</template>

<script>
import Datatable from "@/components/datatable";
import DefaultModal from "@/components/default_modal";
import TransitionDetailModal from "@/components/rental/transition_detail_modal";
import TransitionModal from "@/components/rental/transition_modal";
import {getTransitions, removeTransition} from "@/services/transitions";
import {getClusters, removeCluster, getCluster, putCluster, postCluster} from "@/services/clusters";
import {getSelectableShops} from "@/services/shops";

export default {
  components: {TransitionModal, TransitionDetailModal, DefaultModal, Datatable},
  data() {
    return {
      transitionModalId: 0,
      transitionModalShow: false,
      transitionModalType: '',
      transitionModalTitle: '',
      transitionDetailModalShow: false,
      clusterModalId: 0,
      clusterModalShow: false,
      clusterModalType: '',
      clusterModalTitle: '',
      tabs: [
        {
          id: 1,
          label: "transitions.available",
          columns: [
            {key: "id", label: "id"},
            {
              key: "start_cluster.name",
              label: "start"
            },
            {
              key: "end_cluster.name",
              label: "end",
            },
            {
              key: "vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.license_plate) return value + "<br>" + "<small>" + (item.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "available_date", label: "available_until", formatter: this.$options.filters.formatDateDefault},
            {
              key: "remaining_contingent",
              label: "contingent",
              formatter: (value, key, item) => {
                return value + " / " + item.contingent
              }
            },
            {key: "notes", label: "notes"},
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            filterQuery.option = "open"
            const response = await getTransitions(filterQuery)
            this.tabs[0].recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showTransitionDetailModal
                },
                {
                  label: this.$t('button.edit'),
                  action: this.showEditTransferModal
                },
                {
                  label: this.$t('button.copy'),
                  action: this.showCopyTransitionModal
                },
                {
                  label: this.$t('button.remove'),
                  action: (entry) => {
                    this.$makeRemoveDialog(this).then((r) => {
                      if (r) removeTransition(entry.id)
                    })
                  }
                }
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t('transitions.add'),
              action: this.showCreateTransitionModal
            },
          ],
          recordsTotal: -1
        },
        {
          id: 2,
          label: "transitions.expired",
          columns: [
            {key: "id", label: "id"},
            {
              key: "start_cluster.name",
              label: "start"
            },
            {
              key: "end_cluster.name",
              label: "end",
            },
            {
              key: "vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.license_plate) return value + "<br>" + "<small>" + (item.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "available_date", label: "available_until", formatter: this.$options.filters.formatDateDefault},
            {key: "state_description", label: "state"},
            {
              key: "remaining_contingent",
              label: "contingent",
              formatter: (value, key, item) => {
                return value + " / " + item.contingent
              }
            },
            {key: "notes", label: "notes"},
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            filterQuery.option = "removed"
            const response = await getTransitions(filterQuery)
            this.tabs[1].recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showTransitionDetailModal
                },
                {
                  label: this.$t('button.restore'),
                  action: this.showRestoreTransitionModal
                },
                {
                  label: this.$t('button.copy'),
                  action: this.showCopyTransitionModal
                },
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t('transitions.add'),
              action: this.showCreateTransitionModal
            },
          ],
          recordsTotal: -1
        },
        {
          id: 3,
          label: "clusters.active",
          columns: [
            {key: "id", label: "id"},
            {key: "name", label: "name"},
            {key: "notes", label: "notes"},
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            const response = await getClusters(filterQuery)
            this.tabs[2].recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showClusterDetailModal
                },
                {
                  label: this.$t('button.edit'),
                  action: this.showEditClusterModal
                },
                {
                  label: this.$t('button.remove'),
                  action: (entry) => {
                    this.$makeRemoveDialog(this).then((r) => {
                      if (r) removeCluster(entry.id)
                    })
                  }
                }
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t('clusters.add'),
              action: this.showCreateClusterModal
            }
          ],
          recordsTotal: -1
        },
      ],
      clusterModalInputs: [
        {key: "name", label: "common.name", required: true},
        {
          key: "shop_ids",
          label: "multiples.shop.other",
          type: 'select',
          multiple: true,
          required: true,
          fetchRecords: (filterQuery) => {
            return getSelectableShops(filterQuery)
          }
        },
        {key: "notes", label: "common.notes",},
      ]
    }
  },
  methods: {
    showCreateTransitionModal() {
      this.transitionModalId = 0
      this.transitionModalType = 'create'
      this.transitionModalTitle = this.$t('transitions.add')
      this.transitionModalShow = true
    },
    showEditTransferModal(entry) {
      this.transitionModalId = entry.id
      this.transitionModalType = 'edit'
      this.transitionModalTitle = this.$t('transitions.edit')
      this.transitionModalShow = true
    },
    showRestoreTransitionModal(entry) {
      this.transitionModalId = entry.id
      this.transitionModalType = 'restore'
      this.transitionModalTitle = this.$t('transitions.restore')
      this.transitionModalShow = true
    },
    showCopyTransitionModal(entry) {
      this.transitionModalId = entry.id
      this.transitionModalType = 'create'
      this.transitionModalTitle = this.$t('transitions.add')
      this.transitionModalShow = true
    },
    showTransitionDetailModal(entry) {
      this.transitionModalId = entry.id
      this.transitionDetailModalShow = true
    },
    showCreateClusterModal() {
      this.clusterModalId = 0
      this.clusterModalType = 'create'
      this.clusterModalTitle = this.$t('clusters.add')
      this.clusterModalShow = true
    },
    showEditClusterModal(entry) {
      this.clusterModalId = entry.id
      this.clusterModalType = 'edit'
      this.clusterModalTitle = this.$t('clusters.edit')
      this.clusterModalShow = true
    },
    showClusterDetailModal(entry) {
      this.clusterModalId = entry.id
      this.clusterModalType = 'show'
      this.clusterModalTitle = this.$t('clusters.show')
      this.clusterModalShow = true
    },
    fetchCluster(id) {
      return getCluster(id)
    },
    submitCluster(entry) {
      if (this.clusterModalType === 'create') return postCluster(entry)
      else return putCluster(entry.id, entry)
    }
  }
}
</script>