import {getPathPrefix, getRecord, getRecords, postRecord, putRecord, removeRecord} from "@/services/api";

export function getTransitions(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/web/api/v1/' + getPathPrefix() + 'transitions.json', filterQuery, options)
}

export function getTransition(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'transitions/' + id + '.json', filterQuery, options)
}

export function postTransition(data, options = {}) {
    return postRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'transitions.json', data, options)
}

export function putTransition(id, data, options = {}) {
    return putRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'transitions/' + id + '.json', data, options)
}

export function restoreTransition(id, data, options = {}) {
    return putRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'transitions/' + id + '/restore.json', data, options)
}

export function removeTransition(id, options = {}) {
    return removeRecord('/prologistics/web/api/v1/' + getPathPrefix() + 'transitions/', id, options)
}